export default {
    val:[
        {
            name:'陈**',
            phone:'135****7932',
            time:'1小时前'
        },
        {
            name:'冀**',
            phone:'158****2137',
            time:'1小时前'
        },{
            name:'盖**',
            phone:'188****6216',
            time:'1小时前'
        },
        {
            name:'牛**',
            phone:'187****6094',
            time:'1小时前'
        },{
            name:'张**',
            phone:'150****4862',
            time:'1小时前'
        },{
            name:'叶**',
            phone:'135****5208',
            time:'1小时前'
        },{
            name:'吕**',
            phone:'173****0405',
            time:'5分钟前'
        },{
            name:'李**',
            phone:'183****1109',
            time:'5分钟前'
        },{
            name:'崔**',
            phone:'186****0186',
            time:'5分钟前'
        },{
            name:'陈**',
            phone:'153****6893',
            time:'30分钟前'
        },{
            name:'扈**',
            phone:'138****6084',
            time:'30分钟前'
        },{
            name:'臧**',
            phone:'134****6808',
            time:'30分钟前'
        },{
            name:'陈**',
            phone:'152****8595',
            time:'30分钟前'
        },{
            name:'陈**',
            phone:'132****6152',
            time:'30分钟前'
        },{
            name:'张**',
            phone:'181****0727',
            time:'30分钟前'
        },{
            name:'董**',
            phone:'130****8938',
            time:'1天前'
        },{
            name:'蒋**',
            phone:'180****9561',
            time:'1天前'
        },{
            name:'许**',
            phone:'182****4566',
            time:'2天前'
        },{
            name:'马**',
            phone:'170****3666',
            time:'2天前'
        },{
            name:'王**',
            phone:'131****5381',
            time:'2天前'
        },{
            name:'陈**',
            phone:'132****6152',
            time:'2天前'
        },{
            name:'王**',
            phone:'147****6297',
            time:'刚刚领卡'
        },{
            name:'黄*',
            phone:'178****8922',
            time:'刚刚领卡'
        },{
            name:'周**',
            phone:'189****5720',
            time:'刚刚领卡'
        },{
            name:'谢**',
            phone:'158****1359',
            time:'刚刚领卡'
        },{
            name:'陈**',
            phone:'172****7040',
            time:'刚刚领卡'
        },{
            name:'郑**',
            phone:'166****7210',
            time:'刚刚领卡'
        },{
            name:'黄**',
            phone:'136****4082',
            time:'刚刚领卡'
        },{
            name:'樊**',
            phone:'137****8069',
            time:'刚刚领卡'
        },{
            name:'夏**',
            phone:'156****0860',
            time:'刚刚领卡'
        },{
            name:'金**',
            phone:'137****0687',
            time:'刚刚领卡'
        },{
            name:'陈**',
            phone:'159****2128',
            time:'刚刚领卡'
        },{
            name:'席**',
            phone:'187****0772 ',
            time:'刚刚领卡'
        }
    ]
}