<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
  </div>
</template>
<script>
  export default({
    mounted(){
      window.addEventListener('unload', this.saveState)
    },
    methods:{
      saveState() {
          sessionStorage.setItem('state', JSON.stringify(this.$store.state))
          document.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
              event.preventDefault()
            }
          });
          document.addEventListener('gesturestart', function(event) {
            event.preventDefault()
          })
        },
    }
  })
</script>
<style>
html,body{
  padding: 0;
  margin: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
body{
  background: url('./assets/bg.jpg') no-repeat;
  background-size: cover;
}
</style>
