<template>
  <div class="home">
      <div class="query" ref="query" id="query" @click="queryClick">
        物流查询
      </div>
      <!-- <div class="kfimgbox" id="kfimgbox" ref="query" @click="kfClick">
        <img class="kfimg" src="../assets/images/kf2.png" alt="">
        领卡咨询
      </div> -->
      <img class="img" id="img" ref="img" src="../assets/images/1.jpg" alt="">
      <div class="formbox" ref="form">
        <van-form @submit="onSubmit">
          <div class="form-title">
            填写信息领卡
          </div>
          <!-- <div class="form-tips">
            （此链接是领卡页面，查询流量话费等请勿提交信息！！！）
          </div>
          <div class="form-tips">
            （如需领卡请提交信息，24小时内发出）
          </div> -->
            <van-field
              v-model="username"
              name="username"
              label="姓名"
              placeholder="请输入收件人姓名(已加密)"
              :rules="[{ required: true, message: '请填写收件人姓名' }]"
            />
            <van-field
              v-model="phone"
              name="phone"
              label="联系号码"
              placeholder="请输入联系电话(已加密)"
              maxlength="11"
              type="tel"
              :rules="[{ pattern, message: '请输入正确的联系电话' }]"
            />
            <van-field
              readonly
              clickable
              name="area"
              :value="value"
              label="地区选择"
              placeholder="点击选择省市区(已加密)"
              @click="showArea = true"
              :rules="[{ required: true, message: '请选择地址' }]"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-area
                :area-list="areaList"
                @confirm="onConfirm"
                @cancel="showArea = false"
              />
            </van-popup>
            <van-field
              v-model="address"
              name="address"
              label="详细地址"
              placeholder="请输入详细地址(已加密)"
              :rules="[{ required: true, message: '请输入详细地址' }]"
            />
            <div class="btncl">
              <van-button class="btnvan"  style="font-size:1rem" block type="info" native-type="submit">支付10元 包邮到家</van-button>
              <div class="kfzx" @click="kfClick">客服咨询</div>
            </div>
        </van-form>
        <div class="newClient">
            <div class="client-title">
              最新报名客户
            </div>
            <!-- <div class="client-content marquee_box" :class="{marquee_top:animate}">
                <div v-for="n in 30" class="tran">
                    <span>{{n}}</span>
                </div>
            </div> -->
             <div class="marquee_box">
                <ul class="marquee_list" :class="{marquee_top:animate}">
                        <li v-for="(item,index) in marqueeList" class="li_list">
                          <div class="">{{item.name}}</div>
                          <div>{{item.phone}}</div>
                          <div>{{item.time}}</div>
                        </li>
                </ul>
            </div>
        </div>
      </div>
      <!-- <div>
        <video class="video" src="../../src/assets/images/b.mp4" controls="controls" poster="../../src/assets/images/b.jpg"></video>
      </div> -->
      <div>
        <img class="img" src="../assets/images/2.jpg" alt="">
        <img class="img" src="../assets/images/3.jpg" alt="">
        <img class="img" src="../assets/images/4.jpg" alt="">
        <!-- <img class="img" src="../assets/2.jpg" alt=""> -->
      </div>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper">
          <van-loading size="24px">加载中...</van-loading>
        </div>
      </van-overlay>
      <span v-show="false" class="copy-btn" id="copy" v-clipboard:copy="'kf15093319383'" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</span>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import datalist from '../assets/data.js'
import telPhone from '../assets/tel.js'
export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data() {
    return {
      show:false,
      animate: false,
      value: '',
      username: '',
      phone:'',
      address:'',
      showArea: false,
      areaList: datalist, // 数据格式见 Area 组件文档
      marqueeList:telPhone.val,
      // screenWidth:document.body.clientWidth,
      screenWidth:'',
      setint:null,
      uData:'',
      orderId:'',
      number:0,
      pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    };
  },
  created: function() {
    // 页面显示
    setInterval(this.showMarquee, 2000);
  },
  mounted(){
    const that = this
    let a = this.$store.state.uData
    this.uData = a
    this.orderId = this.$store.state.orderId
    let id = this.$store.state.orderId
    // if(id){
    //   this.orderId = id
    //   console.log("有orderiD")
    //   this.show = true
    //   this.setint = setInterval(()=>{
    //     this.queryPay(id)
    //   },4000) 
    // }
    window.onresize = () => {
        return (() => {
            // window.screenWidth = document.body.clientWidth
            window.screenWidth = document.getElementById('img').offsetLeft
            that.screenWidth = window.screenWidth
        })()
    }
    this.screenWidth = this.$refs.img.offsetLeft
  },
  methods: {
    showMarquee: function() {
      this.animate = true;
      setTimeout(() => {
        this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500);
    },
    kfClick(){
      window.location.href = 'https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d47750c3274d9fed1be7e23031f0d5309945731555ac145e1665034aeb51f2ad563edcea1e705adbccdd284efab6cefb'
    },
    queryClick(){
      // this.show = true
      // this.$dialog.alert({
      //   title:'加微信咨询单号',
      //   message: 'kf15093319383',
      //   confirmButtonText:'点击复制微信号'
      // }).then(() => {
      //   // on close
      //   let copy = document.getElementById('copy')
      //   copy.click()
      // });
      this.$router.push({
        path:'/about'
      })
    },
    onCopy(e){
      this.$toast.success('内容已复制到剪切板！');
    },
    onError(){
      console.log('复制失败')
    },
    upData(){
      // this.show = true
        this.axios({
            method: 'get',
            url: '/hundred/api/simcard/submitOrderInfo',
            params: {
                "address": this.uData.area,
                "addressDetails": this.uData.address,
                "cardType": 10,
                "name": this.uData.username,
                "phone": this.uData.phone,
                "payMoney":10
            }
        })
        .then(res=>{
            // this.show = true
            this.orderId = res.data.data.orderNumber
            this.$store.dispatch("getAcOrderId",res.data.data.orderNumber)
            // this.setint = setInterval(()=>{
            //     this.queryPay(res.data.data.orderId)
            // },4000) 
            window.location.href = res.data.data.mweb_url; 
        })
    },
    onSubmit(values) {
      this.uData = values
      this.$store.dispatch("getAcUData",values)
      // this.payClick()
      this.upData()
    },
    //支付
    payClick() {
      let obj = {
        face: 20,
        payType: 'MWEB', //  移动端
      };
      this.axios({
        url: "/hundred/WxPay/getPayQr",
        method: "get",
        params: obj
      }).then(res => {
          this.show = true
          this.orderId = res.data.data.orderId
          this.$store.dispatch("getAcOrderId",res.data.data.orderId)
          this.upData()
          // this.setint = setInterval(()=>{
          //     this.queryPay(res.data.data.orderId)
          // },4000) 
          window.location.href = res.data.data.mwebUrl; 
      })
    },
    queryPay(e){
      // 查询支付状态
        this.axios({
        url: "/hundred/xushangbaiji/selectstatus",
        method: "post",
        data: {
          orderNumber:e
        }
      }).then(res=>{
        this.number++
        if(this.number > 6){
          this.$store.dispatch("getAcOrderId",'')
          this.show = false
          clearInterval(this.setint); // 清除定时器
          return false
        }
        this.show = true
        if(res.data.code == 0){
          this.show = false;
          clearInterval(this.setint); // 清除定时器
          this.setint = null;
          this.$dialog.alert({
            title: '提示',
            message: "下单成功！",
          }).then(() => {
            this.$router.push('/')
          });
        }
      })
    },
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.showArea = false;
    },
  },
  watch:{
    screenWidth(a){
      let right = document.getElementById('query')
      // let kfimg = document.getElementById('kfimgbox')
      var u = navigator.userAgent , app =  navigator.appVersion
      let p = !!u.match(/AppleWebKit.*Mobile.*/)
      if(!p){
        // kfimg.style.right = a+16 + 'px'
        right.style.right = a+16 + 'px'
      }else{
        // kfimg.style.right = a + 'px'
        right.style.right = a + 'px'
      }
    }
  }
}
</script>
<style scoped>
.kfimgbox{
  top: 128px;
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 1;
  font-size: 14px;
  text-align: center;
  color: #336699;
  /* background-color: bisque; */
}
.kfimg{
  width: 100%;
  height: 100%;
}
.query{
  position: fixed;
  width: 28px;
  height: 28px;
  text-align: center;
  top: 80px;
  color: #fff;
  border: 2px solid #336699;
  border-radius: 30px;
  z-index: 99999999;
  font-size: 0.7rem;
  line-height: 14px;
  padding: 8px;
  cursor: pointer;
  background-color: #336699;
  z-index: 1;
}
  .home{
    /* width:55%; */
    max-width: 40rem;
    min-width: 20rem;
    margin: 0 auto;
    height: 100%;
  }
  .img{
    width: 100%;
    height: auto;
    display: block;
  }
  .formbox{
    background: #fff;
  }
  .form-title{
    text-align: center;
    font-size: 1.5rem;
    /* color: blueviolet; */
    padding: 15px 0;
  }
  .form-tips{
     text-align: center;
     font-size: 0.8rem;
     color: red;
  }
  .newClient{
    font-size: 1.3rem;
    padding-bottom: 10px;
  }
  .client-title{
    padding: 0px 0 10px 0;
    text-align: center;
    font-weight: bold;
  }
  .client-content{
    width: 100%;
    height: 10rem;
    overflow: hidden;
  }
  .tran{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  }
  .btncl{
    display: flex;
    font-size: 0.8rem;
    margin: 15px 0;
  }
  .btnvan{
    border-radius: 30px;
  }
  .kfzx{
    font-size: 1rem;
    width: 150px;
    background: #1989fa;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; */
    border-radius: 30px;
    margin-left: 6px;
    cursor: pointer;
  }
  .marquee_top {
  transition: all 0.5s;
  margin-top: -30px;
}
.marquee_box{
  display: block;
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.marquee_list {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.marquee_list li {
  height: 30px;
  line-height: 30px;
  font-size: 1rem;
  text-align: center;
  /* padding-left: 20px; */
}

/* .marquee_list li span {
  padding: 0 2px;
} */
.li_list{
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.li_list div{
  width: calc(100% / 3.1);
  /* border: 1px solid red; */
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
  }
  .video{
    width: 100%;
    height: auto;
  }
</style>
