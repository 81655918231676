import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')): {
    uData:'',
    orderId:'',
    reData:''
  },
  mutations: {
    setUData(state,v){
      state.uData = v
    },
    setOrderId(state,v){
      state.orderId = v
    },
    setReData(state,v){
      state.reData = v
    }
  },
  actions: {
    getAcUData(store,v){
      store.commit("setUData",v)
    },
    getAcOrderId(store,v){
      store.commit("setOrderId",v)
    },
    getAcReData(store,v){
      store.commit("setReData",v)
    }
  },
  modules: {
  }
})
